body,
#root * {
  font-family: Helvetica, Verdana, sans-serif;
}
body::-webkit-scrollbar {
  width: 1em;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
#root {
  height: 100%;
}
.leaflet-draw-toolbar {
  opacity: 0;
}

div.leaflet-popup-content {
  width: 100% !important;
  border-radius: 0;
}

div.leaflet-popup {
  width: 450px;
}

@media (max-width: 768px) {
  div.leaflet-popup {
    width: 88vw;
  }
}
div.leaflet-popup-content-wrapper {
  border-radius: 0;
}

.editing-popup {
  color: red;
}

span.locked {
  color: #891c00;
}

.leaflet-popup-content {
  margin: 0 !important;
}

/* (GeoArea creation) Make the first dropped editable polygon marker bright red to highlight it clearly to end-user */
div.leaflet-editable-drawing > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-marker-pane > div:nth-child(1) {
  background-color: red;
}

.non-active-editing {
  background-image: -webkit-linear-gradient(
    300deg,
    #ebf0ff 25%,
    #e5e6ff 25%,
    #e5e6ff 50%,
    #ebf0ff 50%,
    #ebf0ff 75%,
    #e5e6ff 75%,
    #e5e6ff 100%
  ) !important;
  background-image: linear-gradient(
    150deg,
    #ebf0ff 25%,
    #e5e6ff 25%,
    #e5e6ff 50%,
    #ebf0ff 50%,
    #ebf0ff 75%,
    #e5e6ff 75%,
    #e5e6ff 100%
  ) !important;
  background-size: 120px 69.28px !important;
}

.active-editing {
  background-image: -webkit-linear-gradient(
    45deg,
    #ffcccc 25%,
    #ffdbdb 25%,
    #ffdbdb 50%,
    #ffcccc 50%,
    #ffcccc 75%,
    #ffdbdb 75%,
    #ffdbdb 100%
  ) !important;
  background-image: linear-gradient(
    45deg,
    #ffcccc 25%,
    #ffdbdb 25%,
    #ffdbdb 50%,
    #ffcccc 50%,
    #ffcccc 75%,
    #ffdbdb 75%,
    #ffdbdb 100%
  ) !important;
  background-size: 56.57px 56.57px !important;
}

.ant-card-actions > li .delete-text-allowed {
  color: red;
}

.ant-card-actions > li .delete-text-allowed:hover {
  color: #0173B4;
}

/* Giving a 'disallowed button' flavour to delete action link text - retains grey-out even on hover */
.ant-card-actions > li .delete-text-disallowed {
  color: #8c8c8c !important;
}

/* Done to force Popover to correct z-index visibility when triggered from with a modal*/
.ant-popover.delete-geo-area-popover-allowed, .ant-popover.delete-geo-area-popover-disallowed {
  z-index: 99999 !important;
}

.ant-popover.delete-geo-area-popover-allowed .ant-popover-title, .ant-popover.delete-geo-area-popover-disallowed .ant-popover-title {
  color: red;
  background-color: #ffd1ce;
}

.ant-popover.delete-geo-area-popover-allowed .ant-popover-inner-content {
  color: darkred;
  background-color: #ffebc3;
}

.ant-popover.delete-geo-area-popover-disallowed .ant-popover-inner-content {
  color: black;
  background-color: #ffebc3;
}

.ant-card-actions > li {
  color: rgba(0, 0, 0, 0.81) !important;
}

.ant-card-body hr {
  border-width: 1px;
  border-color: black;
}

.loader-screen {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99999999;
  text-align: center;
  padding-top: 200px;
}

.loader-screen h1 {
  color: #ffffff;
}

.hide {
  display: none !important;
}

.autoselect-checkbox {
  padding-left: 40px !important;
}

.leaflet-container {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.leaflet-control-attribution {
  display: none;
}
.leaflet-draw-toolbar {
  opacity: 1;
}

div.leaflet-popup-content-wrapper {
  border-radius: 0;
}
.leaflet-filterbox {
  background-color: #ffffff;
  padding: 4px;
  min-width: 200px;
}

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-container {
  overflow: hidden;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  -webkit-filter: inherit;
          filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  /* width: 0; */
  /* height: 0; */
  box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 999;
}
.leaflet-popup {
  max-width: 90vw;
}
.leaflet-map-pane.leaflet-pane {
  z-index: 9999 !important;
}

body .ant-notification {
  z-index: 1000000000;
}

.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  right: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078a8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;

  margin: -10px auto 0;

  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: 'progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)';
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable,
.leaf {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: '';
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}
.hidden {
  display: none;
}
div h4 {
  margin: 20px 0 10px 0;
  line-height: 1;
  display: inline-block;
  color: #777;
  font-size: 18px;
  border-bottom: 1px dotted #ccc;
  clear: both;
}
.mini-loader {
  margin: 20px 0 0 0;
}
.mini-loader span {
  display: inline-block;
  padding-left: 10px;
}
.form-row {
  margin: 15px 0 0 0;
}
.form-row label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  display: block;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  z-index: 999999999999;
}
.loading > div {
  text-align: center;
  margin: 100px 0 0 0;
}
.loading h2 {
  font-weight: bold;
  font-size: 18px;
}
#legend ul {
  padding: 0;
  margin: 0;
}
#legend li {
  line-height: 20px;
  list-style: none;
  margin: 15px 0 0 0;
  padding: 0;
}
#legend li span {
  display: inline-block;
  float: left;
  width: 20px;
  height: 20px;
  border: 2px solid;
  margin: 0 10px 0 0;
  box-sizing: border-box;
}
#legend li span.published {
  border-color: #343434;
  background: rgba(1, 115, 180, 0.4);
}
#legend li span.unpublished {
  border-color: #343434;
  background: rgba(1, 115, 180, 0.4);
  border-style: dashed;
}
#legend li span.edited {
  border-color: red;
  background: rgba(255, 0, 0, 0.4);
}
#upload {
  text-align: center;
  background: rgb(250, 250, 250);
  border: 1px dashed rgb(217, 217, 217);
  position: relative;
  min-height: 130px;
  margin: 30px 0 0 0;
}
#upload.waiting:hover {
  background: rgb(240, 240, 240);
}
#upload input {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  opacity: 0;
  z-index: 9999;
}
.upload-area {
  z-index: 9998;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 30px;
  box-sizing: border-box;
}
#upload .progress {
  padding: 30px;
}

.ant-upload.ant-upload-drag {
  height: auto !important;
}

.overwrite-import-switch.ant-switch.ant-switch-checked {
  background-color: #ff0a00;
}

.critical-modal .ant-modal-content {
  background-image: -webkit-linear-gradient(
    45deg,
    #fff2f2 25%,
    #ffffff 25%,
    #ffffff 50%,
    #fff2f2 50%,
    #fff2f2 75%,
    #ffffff 75%,
    #ffffff 100%
  );
  background-image: linear-gradient(
    45deg,
    #fff2f2 25%,
    #ffffff 25%,
    #ffffff 50%,
    #fff2f2 50%,
    #fff2f2 75%,
    #ffffff 75%,
    #ffffff 100%
  );
  background-size: 56.57px 56.57px;
}

/*
 * GOTO
*/
#goto {
  z-index: 9999999;
  position: relative;
  display: inline-block;
}
.ant-select {
  width: 100%;
}
#goto-content {
  width: 360px;
  max-width: 90%;
  position: relative;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
}
#goto-content label {
  font-weight: bold;
  display: block;
}
#goto-content small {
  font-size: 11px;
}
#goto .goto-button.selected {
  padding-right: 30px;
}
#goto .goto-button {
  border-radius: 0 4px 4px 0;
}
#goto .close {
  margin-left: 5px;
  display: block;
  position: absolute;
  right: 10px;
  z-index: 9999999999;
  top: 10px;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .leaflet-control-zoom {
    display: none;
  }
  #goto .close {
    top: 6px;
  }
  #goto-content {
    right: -65px;
  }
}

@-webkit-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
    fill: #0173b4;
  }
  30% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
    fill: yellow;
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    fill: #0173b4;
  }
  90% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    fill: yellow;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    fill: #0173b4;
  }
}

@keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
    fill: #0173b4;
  }
  30% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
    fill: yellow;
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    fill: #0173b4;
  }
  90% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    fill: yellow;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    fill: #0173b4;
  }
}

.fresh-polygon {
  display: inline-block;
  fill: #0173b4;
  -webkit-animation-name: pulse_animation;
          animation-name: pulse_animation;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
}
.stale-polygon {
  opacity: 0;
  -webkit-transition: 1s;
  transition: 1s;
  fill: #0173b4;
  -webkit-transform-origin: center;
          transform-origin: center;
}

body div.ant-modal-wrap {
  z-index: 100000;
}

.parentSelector .ant-select-tree-treenode-disabled > span span {
  color: c !important;
}

/* .parentSelector .ant-select-tree-treenode-disabled > span span:after { */
/*   color: #ffffff; */
/*   background: rgba(0, 0, 255, 0.71); */
/*   padding: 2px; */
/*   border-radius: 2px; */
/*   content: 'Already exists'; */
/*   right: 0px; */
/*   font-size: 10px; */
/*   margin-left: 10px; */
/* } */

.unauthorised {
  padding: 10px;
}

#overlay-error span {
  float: left;
  font-size: 40px;
  padding: 0 20px 0 0;
  color: #c6241b;
  margin: -10px 0 0 0;
  display: inline-block;
}

.popup-dropdown-link-style {
  position: absolute;
  top: -10px;
  right: 20px;
  color: #c3c3c3;
}

.popup-dropdown-link-style span {
  color: #808080;
  font-size: 20px;
  font-weight: bold;
}

.popup-dropdown-link-style span:hover {
  color: #636363;
}

.edit-buttons-wrapper {
  position: relative;
  width: 300px;
}

.edit-buttons-wrapper button {
  position: absolute;
  right: -10px;
  top: 0;
  padding: 0 3px;
  border-radius: 0;
}

.edit-buttons-wrapper .ant-btn-danger {
  right: -30px;
}

#depth-buttons {
  z-index: 9999999;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
@media (max-width: 768px) {
  #depth-buttons {
    right: auto;
    left: 30px;
  }
}

.centered {
  text-align: center;
}
.align-right {
  text-align: right;
}
#example-image {
  max-width: 94%;
  border: 1px solid #000;
}
.example-modal .ant-modal-close {
  display: none;
}
.example-footer button {
  float: right;
  display: inline-block;
}
.scroll-overflow {
  overflow: hidden;
  overflow-y: auto;
}
.scroll-overflow::-webkit-scrollbar {
  width: 1em;
}
.scroll-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scroll-overflow::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
#root .ant-tree li .ant-tree-node-content-wrapper {
  display: inline;
}
#root .ant-tree li {
  white-space: normal;
}

#root .leaflet-marker-draggable {
  width: 12px !important;
  height: 12px !important;
  margin-left: -6px !important;
  margin-top: -6px !important;
}

@media (max-width: 768px) {
  #root .leaflet-marker-draggable {
    width: 8px !important;
    height: 8px !important;
    margin-left: -4px !important;
    margin-top: -4px !important;
  }
}

#root .leaflet-top.leaflet-left {
  z-index: 9999;
}
.leaflet-google-mutant {
  height: 100% !important;
}

.editable {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-left: 10px;
}
.editable:hover {
  color: black;
}
.editable-select .ant-select-selection {
  background: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}
.popup-row .ant-btn-group {
  float: right;
  display: inline-block;
}
.last-modified {
  font-size: 13px;
  display: block;
  overflow: hidden;
  line-height: 23px;
}
.last-modified span {
  float: left;
}
.popup-row {
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}
.popup-row {
  line-height: 24px;
}
.create-types {
  padding: 15px 10px;
}
.create-types .form-row {
  overflow: hidden;
}
.create-types-content {
  margin: 20px 0 0 0;
}
.editable-title {
  margin: 0 0 5px 0;
}
.editable-title h3,
.editable-title input {
  font-size: 15px;
  font-weight: bold;
  background: none;
  border: none;
  width: 80%;
  color: #000;
  line-height: 23px;
  font-family: Helvetica, Arial, sans-serif;
}
.editable-title h3 {
  padding: 4px;
}
.editing-title {
  width: 100%;
  padding: 3px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.editing-title .ant-btn-group {
  float: right;
}
.depth-editable input {
  width: 100%;
  line-height: 23px;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.6);
}
/* 
 * Google Maps API puts an iframe with URL about:blank (for some unknown reason)
 * and this stops touch events working on geoarea popup
*/
iframe[src*="google.com/maps"] {
  display: none !important;
}

.hierarchy-tree {
  list-style: none;
  padding-left: 0;
}
.hierarchy-tree li {
  color: rgba(0, 0, 0, 0.65);
  margin: 6px 0 0 0;
}
.tree-checkbox {
  padding: 0 8px;
}
.tree-dropdown {
  width: 12px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
}
.tree-title {
  padding-left: 5px;
}
.hierarchy-tree .tree-icon.published {
  color: rgb(2, 61, 255);
}
.hierarchy-tree .tree-icon {
  color: #ccc;
}
.hierarchy-tree .hierarchy-tree {
  margin: 8px 0 0 15px;
}

